import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LayoutService } from '../../layout/service/layout-service.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private layoutService: LayoutService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const userPermissions = this.layoutService.permissions;
    const allowedRoles: string[] = route.data['allowedRoles'] || [];

    // Check if super user, allow navigation
    if (userPermissions?.includes('super_user')) {
      return true;
    }

    // Check if allowedRoles is not in the config, allow navigation
    if (!allowedRoles || allowedRoles.length === 0) {
      return true;
    }
    // Check if any user role starts with one of the allowed roles
    const hasAccess = allowedRoles.some((allowedRole) =>
      userPermissions?.some((userPermission) => userPermission.startsWith(allowedRole))
    );

    if (!hasAccess) {
      this.router.navigate(['/access-denied']);
    }

    return hasAccess;
  }
}
